import React from 'react'
import { Waypoint } from 'react-waypoint'

const MailTo = () => {
    return (
        <div className="mail-to-wrapper" id="js-mail-to-wrapper">
            <Waypoint bottomOffset="200px" onEnter={() => {
                let currentElement = document.getElementById("js-mail-to-wrapper");
                currentElement.classList.add('show');
            }} />
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12">
                        <h3>Zit jouw vraag er niet tussen?</h3>
                        <p className="flat-text">Neem dan gerust contact met ons op en stel jouw vraag!</p>
                        <div className="form-wrapper">
                        <form name="FAQ" method="POST" data-netlify="true">
                            <input type="hidden" name="form-name" value="FAQ" />
                            <p>
                                <label>Naam: <input type="text" name="naam" required /></label>
                            </p>
                            <p>
                                <label>Email: <input type="email" name="email" required /></label>
                            </p>
                            <p>
                                <label>Vraag: <input type="textarea" name="telefoon" required /></label>
                            </p>
                            <p>
                                <button type="submit">Stel uw vraag</button>
                            </p>
                        </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MailTo;
