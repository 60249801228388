import React, { useState } from 'react'
import { useStaticQuery } from "gatsby"
import { Waypoint } from 'react-waypoint'

const SubscriptionOverview = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulAbbonement(sort: {fields: orderId, order: ASC}) {
            edges {
              node {
                color
                price
                subscriptonParts
                typeSubscription
              }
            }
          }
    }
  `)

    const [showPopup, setPopup] = useState(false);
    const [userRequest, setUserRequest] = useState({
        type: "",
        price: 1
    })
    const { type, price } = userRequest;
    const closePopup = () => {
        setPopup(false)
    }
    const startSub = (type, price) => {
        setPopup(true);
        setUserRequest({
            type: type,
            price: price
        })
        console.log("type: " + type + ", price: " + price)
    }

    return (
        <div className="sub-overview-container" id="js-sub-overview-container">
            <Waypoint bottomOffset="200px" onEnter={() => {
                let currentElement = document.getElementById("js-sub-overview-container");
                currentElement.classList.add('show');
            }} />
            <div className="inner">
                <div className="row">
                    {data.allContentfulAbbonement.edges.map((edge, i) => {
                        return (
                            <div className="col-xs-12 col-md-6 col-lg-4 card-wrapper" key={i}>
                                <div className="sub-card">
                                    <div className="card-overlay" style={{ backgroundColor: "#" + edge.node.color }}>
                                        <span className="type-sub">{edge.node.typeSubscription}</span>
                                        <span className="price-sub">€{edge.node.price}</span>
                                        <span className="month-sub">per maand</span>
                                    </div>
                                    <div className="buy-button" style={{ backgroundColor: "#" + edge.node.color, borderColor: "#" + edge.node.color }}>
                                        <span onClick={() => startSub(edge.node.typeSubscription, edge.node.price)}>Start nu</span>
                                    </div>
                                    <ul>
                                        {edge.node.subscriptonParts.map((part, index) => (
                                            <li className="sub-parts" key={index}>
                                                <span>{part}</span>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        )
                    }
                    )}
                    {/* <div className="col-xs-12">
                        <a href="/assets/algemene-voorwarden.pdf" className="terms" download>Download en bekijk de algemene voorwaarde</a>
                    </div> */}
                </div>
            </div>
            <div className={showPopup ? "start-now-form-wrapper show" : "start-now-form-wrapper"}>
                <div className="start-now-form">
                    <div className="close-form" onClick={closePopup}><span>X</span></div>
                    <h3>Je wilt graag het {type} pakket starten</h3>
                    <p className="flat-text">Dit abbonement kost €{price}. Vul onderstaande velden in en wij nemen zo snel mogelijk contact met je op.</p>
                    <div className="form-wrapper">
                        <form name="Abbonement" method="POST" data-netlify="true">
                            <input type="hidden" name="form-name" value="Abbonement" />
                            <p>
                                <label>Naam: <input type="text" name="naam" required /></label>
                            </p>
                            <p>
                                <label>Email: <input type="email" name="email" required /></label>
                            </p>
                            <p>
                                <label>Telefoon: <input type="text" name="telefoon" required /></label>
                            </p>
                            <input type="hidden" name="type" value={type} />
                            <input type="hidden" name="prijs" value={price} />
                            <p>
                                <button type="submit">Start nu</button>
                            </p>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionOverview;