import React from 'react'
import { useStaticQuery } from "gatsby"
import { Waypoint } from 'react-waypoint'

const References = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulReferenties {
            edges {
              node {
                person
                company
                reference {
                  reference
                }
                title
              }
            }
          }
    }
  `)

    return (
        <div className="reference-wrapper" id="js-ref-wrapper">
            <Waypoint bottomOffset="200px" onEnter={() => {
                let currentElement = document.getElementById("js-ref-wrapper");
                currentElement.classList.add('show');
            }} />
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12">
                        <h3>Wij houden van tevreden klanten</h3>
                        <p className="flat-text">Deze bedrijven waren je al voor en delen graag hun ervaringen met ons!</p>
                    </div>
                </div>
                <div className="row">
                    {data.allContentfulReferenties.edges.map((edge, i) => {
                        return (
                            <div className="col-xs-12 col-sm-6 col-lg-4 ref-card-wrapper" key={i}>
                                <div className="ref-card">
                                    <span>Geschreven door <span className="highlight">{edge.node.person} </span>van <span className="highlight">{edge.node.company}</span></span>
                                    <h5>{edge.node.title}</h5>
                                    <p>{edge.node.reference.reference}</p>
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>
        </div>
    )
}

export default References;
