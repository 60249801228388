import React from 'react'
import Layout from '../components/layout'
import Footer from '../components/footer'
import Intro from '../components/intro'
import Bg from '../assets/img/new-banner.jpg'
import Mon from '../assets/img/icons/mon.png'
import Att from '../assets/img/icons/att.png'
import Wifi from '../assets/img/icons/wifi.png'
import BgAbbo from '../assets/img/fm-bg.jpg'

import { Waypoint } from 'react-waypoint'

import SubscriptionsOverview from '../components/subscriptionoverview'
import Faq from '../components/faq'
import MailTo from '../components/mailto'
import References from '../components/references'

const SubscriptionPage = () => {
    return (
        <Layout>
            <Intro title="Fullservice mediabureau" text="Natuurlijk willen ondernemers zichtbaar zijn op internet, maar het ontbeert hen simpelweg vaak aan de tijd om zich hier serieus in te verdiepen. Ons fullservice mediabureau kan alle social media werkzaamheden uit handen nemen en zorgen dat je optimaal gebruikt maakt van de mogelijkheden die er zijn. Wij leggen hieronder uit wat we voor je kunnen doen, wat de missie is van waaruit we werken en uit welke abonnementen je kunt kiezen." />
            <div className="sub-wrapper">
                <div className="banner-med" style={{ backgroundImage: `url(${Bg})` }}>
                    <div className="banner-overlay"></div>
                </div>
                <div className="inner">
                    <div className="row">
                        <div className="col-xs-12 col-lg-8 off-lg-2">
                            <div className="sub-intro" id="js-sub-intro">
                                <Waypoint bottomOffset="200px" onEnter={() => {
                                    let currentElement = document.getElementById("js-sub-intro");
                                    currentElement.classList.add('show');
                                }} />
                                <h3>Wat kunnen wij je bieden</h3>
                                <p className="flat-text">
                                    Vanuit onze online expertise én gebruikmakend van onze achtergrond in de bouwsector denken we graag met je mee hoe u de doelen die je hebt gesteld voor jouw onderneming, ook daadwerkelijk kunt realiseren. Wil je dat we een plan uitdenken voor het beheer van je social media en dat we dit ook voor je uitvoeren? In een persoonlijk intakegesprek bekijken we waar je precies behoefte aan hebt. Nieuwe media als Facebook, Instagram en Twitter bieden volop kansen en wij helpen je om die te benutten. Daarnaast kunnen wij ook zorgen voor al je drukwerk en opmaakwerkzaamheden.

                        </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4">
                            <div className="intro-content" id="js-intro-content-1">
                                <Waypoint bottomOffset="200px" onEnter={() => {
                                    let currentElement = document.getElementById("js-intro-content-1");
                                    currentElement.classList.add('show');
                                }} />
                                <img src={Mon} alt="" />
                                <h4>Dagelijkse posts</h4>
                                <p className="flat-text">Niets is zo dodelijk als een saaie website of social media waar niets gebeurt. Door dagelijks nieuwe, relevante posts te plaatsen, houd je de aandacht vast en verleid je mensen om regelmatig te kijken of er weer iets interessants te lezen is op je social media.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <div className="intro-content" id="js-intro-content-2">
                                <Waypoint bottomOffset="200px" onEnter={() => {
                                    let currentElement = document.getElementById("js-intro-content-2");
                                    currentElement.classList.add('show');
                                }} />
                                <img src={Att} alt="" />
                                <h4>Meer volgers</h4>
                                <p className="flat-text">Als je je social media levend houdt, zullen meer mensen deze willen volgen.</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            <div className="intro-content" id="js-intro-content-3">
                                <Waypoint bottomOffset="200px" onEnter={() => {
                                    let currentElement = document.getElementById("js-intro-content-3");
                                    currentElement.classList.add('show');
                                }} />
                                <img src={Wifi} alt="" />
                                <h4>Meer verkeer</h4>
                                <p className="flat-text">Als volgers je social media ook nog aansprekend genoeg vinden om te delen, verspreidt de aantrekkende werking zich als een olievlek richting potentiële nieuwe klanten. Je naamsbekendheid neemt toe waardoor mensen zich jouw bedrijf weten te herinneren op het moment dat ze je nodig hebben.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-lg-8 off-lg-2">
                            <div className="sub-intro" id="js-sub-intro-2">
                                <Waypoint bottomOffset="200px" onEnter={() => {
                                    let currentElement = document.getElementById("js-sub-intro-2");
                                    currentElement.classList.add('show');
                                }} />
                                <h3>Onze missie</h3>
                                <p className="flat-text">
                                In deze tijd zijn social media onmisbaar geworden voor bouwbedrijven en aannemers. Door actief te zijn en te blijven, bereik je meer mensen en krijg je meer volgers. Wij nemen je met plezier alles uit handen zodat jij als ondernemer kunt focussen op wat je het liefste doet!
                        </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="abbo-wrapper">
                    <div className="abbo-wrapper-bg" style={{ backgroundImage: `url(${BgAbbo})` }}></div>
                    <div className="inner">
                        <div className="row">
                            <div className="col-xs-12 col-lg-8 off-lg-2">
                                <div className="sub-intro" id="js-sub-intro2">
                                    <Waypoint bottomOffset="200px" onEnter={() => {
                                        let currentElement = document.getElementById("js-sub-intro2");
                                        currentElement.classList.add('show');
                                    }} />
                                    <h3>Abonnementen</h3>
                                    <p className="flat-text">
                                        Geen verrassingen achteraf. Als ondernemer wil je precies weten waar je aan toe bent. Om die reden werken wij bij voorkeur met abonnementen. Er zijn drie abonnementen mogelijk:
                        </p>
                                    <p className="flat-text">Wil je geen abonnement maar heb je wel behoefte aan ondersteuning, bijvoorbeeld als het gaat om drukwerk of opmaakwerkzaamheden? Ook dan kijken we graag hoe we je verder kunnen helpen.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SubscriptionsOverview />
                </div>
            </div>
            <Faq />
            <MailTo />
            <References />
            <Footer />
        </Layout>
    )
}

export default SubscriptionPage;