import React, { useEffect } from 'react'
import { useStaticQuery } from "gatsby"
import { Waypoint } from 'react-waypoint'

const Faq = () => {

    const data = useStaticQuery(graphql`
    query {
        allContentfulFaq {
            edges {
              node {
                question
                antwoord {
                  antwoord
                }
              }
            }
          }
    }
  `)

    useEffect(() => {
        const arrow = document.getElementsByClassName('faq-holder');
        const opener = document.getElementsByClassName('opener');
        let dropDownOpen = false;

        for (let i = 0; i < opener.length; i++) {
            opener[i].addEventListener("click", function () {

                if (dropDownOpen === true) {
                    arrow[i].classList.remove("open")
                    dropDownOpen = false
                } else {
                    arrow[i].classList.add("open")
                    dropDownOpen = true
                }

            })
        }
    });

    //   const [showDropDown, setDropDown] = useState(false);
    //   const dropDownHandler = function () {
    //     setDropDown(showDropDown => !showDropDown);
    //   }

    return (
        <div className="faq-wrapper" id="js-faq-wrapper">
            <Waypoint bottomOffset="200px" onEnter={() => {
                let currentElement = document.getElementById("js-sub-overview-container");
                currentElement.classList.add('show');
            }} />
            <div className="inner">
                <div className="row">
                    <div className="col-xs-12 col-md-10 off-md-1">
                        <div className="faq-title-wrapper" id="js-faq-title-wrapper">
                            <Waypoint bottomOffset="200px" onEnter={() => {
                                let currentElement = document.getElementById("js-faq-title-wrapper");
                                currentElement.classList.add('show');
                            }} />
                            <h3>Veelgestelde vragen</h3>
                            <p className="flat-text">Een aantal veelgestelde vragen wordt hieronder beantwoord.</p>
                        </div>
                    </div>
                </div>
                <div className="faq-holder-wrapper" id="js-faq-holder-wrapper">
                    <Waypoint bottomOffset="200px" onEnter={() => {
                        let currentElement = document.getElementById("js-faq-holder-wrapper");
                        currentElement.classList.add('show');
                    }} />
                    <div className="row">
                        {data.allContentfulFaq.edges.map((edge, i) => {
                            return (
                                <div className="col-xs-12 col-md-10 off-md-1" key={i}>
                                    <div className="faq-holder">
                                        <div className="question-holder" id="question-holder">
                                            <div className="opener" id="opener"></div>
                                            <h5>{edge.node.question}</h5>
                                        </div>
                                        <div className="answer-holder">
                                            <p className="flat-text">{edge.node.antwoord.antwoord}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Faq;
